<template>
  <div
    class="max-h-screen h-screen relative lg:hidden bg-black bg-opacity-70"
  >
    <div
      class="px-4 backdrop-filter backdrop-blur-lg text-lg flex items-center justify-center text-white h-full w-full"
    >
      Please Open this in Your Laptop or Desktop!
    </div>
    <div class="absolute cursor-default no-click text-gray-100 text-opacity-20 tracking-wide text-sm font-semibold left-1/2 transform -translate-x-1/2 bottom-4">
        Cube OS
    </div>
  </div>
</template>
